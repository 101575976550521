<template>
 <!-- <div class="about justify-center flex bg-gray-900 items-center text-white"> -->
 <div class="bg-gray-900"> 



	
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-blue-700 hover:text-blue-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-blue-400 rounded-lg">Donator Commands</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-blue-400 inline-flex"></div>
			</div>
		</div>	
	</div>
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">
        
		<p class="text-gray-300 text-left p-2"><span class="bg-blue-500 p-2 inline-block text-white rounded-lg m-2">/glow</span> Changes your in-game chat color, as well as the glow around your character. Uses 0xHEX format. Example: /glow 0xffffff is a white glow. Attached is a color picker. Make sure to page down to the HEX format in the selector.
        <input type="color" id="head" class="inline-block bg-gray-800" name="head"
        value="#e66465">
       </p>
        <p class="text-gray-300 text-left p-2"><span class="bg-blue-500 p-2 inline-block text-white rounded-lg m-2">/size</span> Used to change your characters size. VIP: 75-125, Premium & MVP: 75 - 150</p>	
        <p class="text-gray-300 text-left p-2"><span class="bg-blue-500 p-2 inline-block text-white rounded-lg m-2">/level20</span> Automatically sets your character level to Level 20.</p>	
        <p class="text-gray-300 text-left p-2"><span class="bg-blue-500 p-2 inline-block text-white rounded-lg m-2">/playtime</span> Shows how many hours you have played in-game.</p>	
        <p class="text-gray-300 text-left p-2"><span class="bg-blue-500 p-2 inline-block text-white rounded-lg m-2">/mymaps</span> Allows you to swap between your different vault maps.</p>	
        <p class="text-gray-300 text-left p-2"><span class="bg-purple-500 p-2 inline-block text-white rounded-lg m-2">/clearinv</span> Deletes every item in your inventory.</p>	
        <p class="text-gray-300 text-left p-2"><span class="bg-purple-500 p-2 inline-block text-white rounded-lg m-2">/backpack</span> Gives your character a backpack. Infinite uses.</p>	
        <p class="text-gray-300 text-left p-2"><span class="bg-pink-500 p-2 inline-block text-white rounded-lg m-2">/mvppets</span> Sends all MVP Pet Generators to your gift-chest.</p>	
        <p class="text-gray-300 text-left p-2"><span class="bg-pink-500 p-2 inline-block text-white rounded-lg m-2">/petsize</span> Changes the size of your current pet.</p>	
        <p class="text-gray-300 text-left p-2"><span class="bg-pink-500 p-2 inline-block text-white rounded-lg m-2">/music</span> Changes the music in your own house.</p>	

        </div>
	</div>		

	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-yellow-900 hover:text-yellow-600 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-yellow-400 rounded-lg">Player Commands</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-yellow-400 inline-flex"></div>
			</div>
		</div>	
	</div>
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">
        
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/realm</span> Teleports you to the Realm.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/vault</span> Teleports to your Vault.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/aspecthall</span> Teleports you to the Aspect Hall.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/home</span> Teleports you to your home.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/ghall</span> Teleports to your vault.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/tell</span> Whispers another player.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/mymarket</span> Displays all your market listings.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/oops</span> Removes your last market listing.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/online</span> Displays the amount of online players.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/uptime</span> Displays the uptime of the server.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/uptime</span> Displays the commands you have access to! Making this redudant.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/pc itemName</span> Displays all the market listings and prices for the specified item.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-yellow-700 p-2 inline-block text-white rounded-lg m-2">/aspectinfo</span> Parent command for learning more about aspects.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-indigo-700 p-2 inline-block text-white rounded-lg m-2">/pcreate</span> Create a party.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-indigo-700 p-2 inline-block text-white rounded-lg m-2">/pinvite</span> Invite a player to your party.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-indigo-700 p-2 inline-block text-white rounded-lg m-2">/psummon</span> Summon your party to you.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-green-700 p-2 inline-block text-white rounded-lg m-2">/ginvite</span> Invites a player to your guild.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-green-700 p-2 inline-block text-white rounded-lg m-2">/gkick</span> Kicks a player from your guild.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-green-700 p-2 inline-block text-white rounded-lg m-2">/gleave</span> Leave your guild.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-green-700 p-2 inline-block text-white rounded-lg m-2">/ginfo rewards</span> Claim monthly guild rewards.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-green-700 p-2 inline-block text-white rounded-lg m-2">/g</span> Chat among your guildies.</p>

        <p class="text-gray-300 text-left p-2"><span class="bg-red-700 p-2 inline-block text-white rounded-lg m-2">/ebuyonrane</span> An elite command for converting gold into onrane.</p>


        </div>
	</div>		

	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-yellow-700 hover:text-yellow-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-yellow-400 rounded-lg">Admin Commands</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-yellow-400 inline-flex"></div>
			</div>
		</div>	
	</div>
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">
        <p class="text-gray-300 text-left p-2"><span class="bg-blue-300 p-2 inline-block text-white rounded-lg m-2">/kick playerName</span> Kicks the specified player from the server.</p>
        <p class="text-gray-300 text-left p-2"><span class="bg-blue-300 p-2 inline-block text-white rounded-lg m-2">/mute playerName time</span> Mutes the specified player for the specified minutes.</p>
		</div>
	</div>		

	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-yellow-700 hover:text-yellow-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-yellow-400 rounded-lg">Roles</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-yellow-400 inline-flex"></div>
			</div>
		</div>	
	</div>
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">        

    </div>
	</div>		

  </div>


</template>

<script>
export default {
  name: "Commands",
};
</script>
<style scoped>

.about {
  cursor:url('https://vwiki.valorserver.com/api/item/picture/Insignia%20of%20Valor'), auto;
}

</style>