<template>
<div>
<div v-if="isError">
  <div class=" justify-center flex bg-gray-900 items-center h-screen">
    <div class="text-4xl text-white">Item Not Found</div>
    <router-link to="/items" class="p-2 text-white bg-blue-600 m-2 rounded-lg font-blocky"> Back</router-link>
  </div>
</div>
<div v-else>
 <div class="container px-5 mx-auto">
            <div class="flex flex-col w-full mb-2 text-left ">
              <h1 class="mb-2 text-3xl text-gray-100 tracking-tighter lg:text-7xl md:text-4xl"> {{ $route.params.name }} </h1>
            </div>
            <div class="flex flex-col lg:flex-row lg:space-x-12 ">
              <div class="w-full mt-12 lg:w-1/4">
                <div class="p-4 transition duration-500 ease-in-out transform bg-gray-800 rounded-lg ">
                  <img :src="'https://vwiki.valorserver.com/api/item/picture/' + $route.params.name" class="object-cover w-12 m-2 h-12 mr-2">

                  <div class="container space-y-2 space-x-2">
                   
                  <span v-if="item.Soulbound != undefined" class="min-w-full text-sm font-semibold  py-1 px-2 uppercase tracking-tight rounded text-blue-700 bg-blue-300 uppercase">
                    Soulbound
                  </span>
                  <span class="min-w-full text-sm font-semibold  py-1 px-2 uppercase tracking-tight rounded text-indigo-700 bg-indigo-300 uppercase">
                    {{ item.SlotType || "Unknown" }}
                  </span>
                  <span v-if="item.Relic != undefined" class="text-xs font-semibold p-1 inline-block py-1 px-2 uppercase rounded text-yellow-900 bg-yellow-600 uppercase">
                    Ancient Relic 
                  </span>

                  <span v-if="item.$.setName != undefined" class="text-xs font-semibold p-1 inline-block py-1 px-2 uppercase rounded text-green-400 bg-yellow-200 uppercase">
                    {{ item.$.setName }}
                  </span>
                  <span v-if="item.Legendary != undefined" class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-700 bg-yellow-200 uppercase">
                    Legendary
                  </span>

                  <span v-if="item.FameBonus != undefined" class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-400 bg-red-200 uppercase">
                    {{ JSON.parse(item.FameBonus) }}% Fame Bonus
                  </span>

                  <span v-if="item.Legend != undefined" class="text-xs m-1 font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-700 bg-yellow-300 uppercase">
                    Effect
                  </span>
              
                  <span v-if="item.TrialItem != undefined" class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-700 bg-red-300 uppercase">
                    Trial Item
                  </span>


                  </div>

                  <h1 class="mb-2 p-2 mt-3 text-2xl text-gray-700 hover:text-gray-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-gray-900 rounded-lg">Info</h1>
                  <div class="container text-left space-y-2 space-x-2">
                      <span v-if="item.RateOfFire != undefined" class="text-left text-xs font-semibold py-1 px-2 uppercase rounded text-green-700 bg-green-300 uppercase last:mr-0 mr-1">
                        RoF: {{ item.RateOfFire.toFixed(2) }}%
                      </span>
                      <span v-if="item.NumProjectiles != undefined" class="text-left text-xs font-semibold py-1 px-2 uppercase rounded text-green-700 bg-green-300 uppercase last:mr-0 mr-1">
                        Shots: {{ JSON.parse(item.NumProjectiles) }}
                      </span>
                      <span v-if="item.Range != undefined" class="text-left text-xs font-semibold py-1 px-2 uppercase rounded text-gray-700 bg-indigo-400 uppercase last:mr-0 mr-1">
                        Range: {{ item.Range }}
                      </span>

                      <span v-if="item.ArcGap != undefined" class="text-left text-xs font-semibold py-1 px-2 uppercase rounded text-green-700 bg-green-300 uppercase last:mr-0 mr-1">
                        ArcGap: {{ JSON.parse(item.ArcGap) }}
                      </span>

                  
                      <span v-if="item.Steal != undefined" class="text-left text-xs font-semibold py-1 px-2 uppercase rounded text-green-700 bg-green-300 uppercase last:mr-0 mr-1">
                        Steals HP/MP
                      </span>
                  
                      <span v-if="item.Limited != undefined" class="text-left text-xs font-semibold py-1 px-2 uppercase rounded text-green-700 bg-pink-300 uppercase last:mr-0 mr-1">
                        Limited Edition
                      </span>
                  
                      <span v-if="item.Shard != undefined" class="text-left text-xs font-semibold py-1 px-2 uppercase rounded text-white bg-red-300 uppercase last:mr-0 mr-1">
                        {{ item.Shard }}
                      </span>
                      <span v-if="item.droppedBy != undefined" class="text-left text-xs font-semibold py-1 px-2 uppercase rounded text-white bg-red-300 uppercase last:mr-0 mr-1">
                        {{ item.droppedBy }}
                      </span>
                      
                  </div>
                </div>
                
              </div>
              
              <div class="w-full px-4 mt-12 text-lg leading-snug tracking-tight text-blueGray-500 lg:px-0 lg:w-3/4">
                <div class="header p-4 pl-4 mb-6 italic font-bold tracking-tight text-yellow-700 bg-gray-800 rounded-lg lg:text-4xl"> Info </div>
                <div class="pb-6 pl-4 py-2 mr-1 mb-6 bg-gray-800 rounded-lg">
                 <p class="text-yellow-600">{{ item.Description }}</p>
                 <p v-if="item.MinDamage != undefined" class="text-yellow-600 ">Damage: {{ JSON.parse(item.MinDamage) }} - {{ JSON.parse(item.MaxDamage) }}</p>

                 </div>
              <div  v-if="item.ActivateOnEquip != undefined">
              <div class="header p-4 pl-4 mb-6 italic font-bold tracking-tight text-green-300 bg-gray-800 rounded-lg lg:text-4xl"> On Equip  </div>
              <div class="pb-6 pl-4 py-2 mr-1 mb-6 bg-gray-800  rounded-lg text-left">
                <p  v-for="stat in item.ActivateOnEquip" v-bind:key="stat" class="pb-2 text-gray-300"> {{ stat }}</p>
              </div>
              </div>

              <div v-if="item.Legend != undefined">
                 <div class="p-4 pl-4 mb-6 italic font-bold tracking-tight text-yellow-300 bg-gray-800 rounded-lg lg:text-4xl"> Legendary Power </div>
                 <div class="pb-6 pl-4 py-2 mr-1 mb-6 bg-gray-800  rounded-lg text-left">
                  <p class="pb-6 text-gray-400 bold text-bold"> {{ item.Legend }}</p>  
                </div>
              </div>   
                                           
              </div>
            </div>
          </div>
 </div>       
 </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Item",
  data() {
    return {
      item: "",
      projectile: [],
      error: "",
      isError: false,
    };
  },

  methods: {    
    hexToDec(hexString){
        return parseInt(hexString, 16);
    },
    async getData() {
      try {
        var name = this.$route.params.name;
        const response = await axios.get("https://vwiki.valorserver.com/api/item/" + name);
        this.item = response.data;
        if (this.item.RateOfFire != undefined) {
          this.item.RateOfFire = JSON.parse(this.item.RateOfFire) * 100;
        }
        //this.projectile = JSON.stringify(this.item.Projectile);
        //this.projectile = this.projectile.replace(/['"]+/g, '');

        console.log(response);
      
      } catch (error) {
        console.log(error);
        this.isError = true;
        this.error = "Item not found!";
        this.$route.params.name = "";
        this.item = "";

      }
    },
  },

  created() {
    this.getData();
  },
};
</script>
