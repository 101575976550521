<template>
 <!-- <div class="about justify-center flex bg-gray-900 items-center text-white"> -->
 <div class="bg-gray-900"> 

	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
			
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-green-700 hover:text-green-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-green-400 rounded-lg">Custom Classes</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-green-400 inline-flex"></div>
			</div>
		</div>	
	</div>

	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">
	<div class="flex flex-wrap">
      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-red-400 rounded-lg">Samurai</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="samurai.png">
        <p class="leading-relaxed text-gray-300 mb-4">The Samurai's Sheath acts as a Ninja Star, while keeping it held you're granted the status effect Berserk. If you have the required Magic Points for the specific Sheath you're using you'll release an AoE attack that deals damage depending on your current Sheath..</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 810</p>
		<p class="text-gray-300 text- inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 275</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 30</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 55</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 65</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 70</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 45</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 80</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 80</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 25</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 70</p>

      </div>
      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-purple-400 rounded-lg">Gambler</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="gambler.png">
        <p class="leading-relaxed text-gray-300 mb-4">Gambler is an offensive dagger and leather class. Gambler uses a dice to apply effects to them self but occasionally gets bad effects. The effects are: Bravery, Sick, or Berserk. Bravery increases crit chance and multiplier. The dice themselves also give luck and might in large amounts. Has a high ATT stat.</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 580</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 385</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 70</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 25</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 55</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 55</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 40</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 100</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 150</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 25</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 60</p>
	  </div>     

      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-black bg-black rounded-lg">Drakzix</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="drakzix.png">
        <p class="leading-relaxed text-gray-300 mb-4">Drakzix is an offensive wand class with a robe and a siphon, using the siphon requires health and mana, it will drain both of these slowly while charging the ability you cannot heal during this phase unless you have life steal, upon releasing space bar you will “nuke” the enemies wherever you are aiming, doing lots of damage. Has very poor base stats and requires extensive maxing</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 600</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 900</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 20</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 40</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 55</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 35</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 80</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 30</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 25</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 60</p>
      </div>     


      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-purple-400 rounded-lg">Spirit Hunter</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="spirithunter.png">
        <p class="leading-relaxed text-gray-300 mb-4">Spirit Hunter is a defensive bow class that wears robes. It uses a talisman that summons ghosts, which aid you in battle.</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 660</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 280</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 60</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 18</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 65</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 60</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 60</p>
      </div>     


      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-blue-500 rounded-lg">Mechanic</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="mechanic.png">
        <p class="leading-relaxed text-gray-300 mb-4">The Mechanic is a defensive lance class with relatively high might and luck classes for a lance class. It uses light armor, and has a deployable turret as an ability. This turret when deployed targets and fires at nearby enemies in it radius. Turrets can damage, slow, and daze enemies.</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 700</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 250</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 65</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 15</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 70</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 45</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 70</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 65</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 45</p>
      </div>     


      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-gray-400 rounded-lg">Shrine Maiden</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="shrinemaiden.png">
        <p class="leading-relaxed text-gray-300 mb-4">The Shrine Maiden uses her mystical Charm to weaken her enemies and her Katana to rend them to bits.
The Shrine Maiden's Charm spawns a Torii at your cursor. The Torii will inflict a Buff or Debuff depending on the Charm you're using.</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 650</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 410</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 25</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 65</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 60</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 60</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 85</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 60</p>

	 </div>     



      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-blue-900 rounded-lg">Templar</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="templar.png">
        <p class="leading-relaxed text-gray-300 mb-4">
The holy Templar fights only for justice. He uses his banner to invigorate allies and strike down his enemies with ease.
The Templar's banner Empowers allies, making them regenerate their Magic Points faster. Banners also inflict Invunerable and Surged to self..</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 880</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 125</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 75</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 20</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 65</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 45</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 80</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 30</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 40</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 40</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 60</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 60</p>
      </div>     


      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-red-400 rounded-lg">Blade Master</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="blademaster.png">
        <p class="leading-relaxed text-gray-300 mb-4">The Blademaster brawls with Dual Blades and utilizes the powers of his Jacket.
The Blademaster's Jacket acts with a Offensive and Defensive mode. To switch modes you need at least 1 Surge and the required mana cost for the specific Jacket you're using. The Blade master uses his own weapon type, the Blades.</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 720</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 252</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 40</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 15</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 100</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 95</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 40</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 80</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 80</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 25</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 60</p>
      </div>     


      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6">
        <h2 class="text-lg sm:text text-center text-white font-medium title-font mb-2 font-blocky bg-red-900 rounded-lg">Pirate</h2>
		<img class="object-cover mx-auto justify-center w-16 h-16 pb-2 mt-2 lg:mt-5" src="pirate.png">
        <p class="leading-relaxed text-gray-300 mb-4">The pirate is an offensive sword class that uses light armor and an anchor. The pirate throws out their anchor, which fires through enemies and boomerangs back, slowing them in the process.</p>
        <p class="text-indigo-500 items-center">Stats</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Life"> 660</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Mana"> 190</p>
		<p class="text-gray-300 text-left  inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion of Attack"> 65</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Defense"> 15</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Speed"> 55</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Dexterity"> 60</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Vitality"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Wisdom"> 45</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> 45</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> 25</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> 50</p>
		<p class="text-gray-300 text-left inline-block"><img class="inline-block w-6 h-6" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> 65</p>
      </div>     


	  </div>
		</div>
	</div>	




		
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-red-700 hover:text-red-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-red-900 rounded-lg">Custom Stats</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-red-700 inline-flex"></div>
			</div>
		</div>	
	</div>
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">
			<p class="text-gray-300 inline-block text-left p-2"><img class="inline-block w-12 h-12 m-2" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Restoration"> Restoration / RES: Restoration increases the amount of healing given when using health and mana potions. In addittion, having higher amounts of restoration causes you to automatically refil up to 6 of each potion upon visiting the nexus.</p>
			<p class="text-gray-300 inline-block text-left p-2"><img class="inline-block w-12 h-12 m-2" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Protection"> Protection / PRT: Protection is a Shield which will protect you from all damaging projectiles, It can only soak as much as what it says. So if you have 131 it will protect you from 131 damage. This shield is not affected by defense.</p>
			<p class="text-gray-300 inline-block text-left p-2"><img class="inline-block w-12 h-12 m-2" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Might"> Might / MGT: Might is critical strike damage. You gain 0.1 bonus critical strike damage per 7 points.</p>
			<p class="text-gray-300 inline-block text-left p-2"><img class="inline-block w-12 h-12 m-2" src="https://vwiki.valorserver.com/api/item/picture/Potion Of Luck"> Luck / LUC: Luck is your critical hit chance. You gain 1% critical chance per 10 luck, capping at 100% critical chance at 1000 luck</p>
			<p class="text-gray-300 inline-block text-left p-2"><img class="inline-block w-12 h-12 m-2" src="https://vwiki.valorserver.com/api/item/picture/Insurgency Amulet"> Surge / SG: Surge is gained by killing enemies. You gain 2 surge per enemy that is killed near you. If you reach 100 surge, your protection bar is refilled. Depending on equipped items, surge can also have additional effects. Surge will naturally decay when enemies have not recently been killed.</p>
			<p class="text-gray-300 inline-block text-left p-2"><img class="inline-block w-12 h-12 m-2" src="https://vwiki.valorserver.com/api/item/picture/Jackpot"> Fortune: (only given by items): This will increase your drop chance by %1 per stat point</p>

		</div>
	</div>		

	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-red-700 hover:text-red-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-red-900 rounded-lg">Ascension, Marks & Lost Scripture</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-red-700 inline-flex"></div>
			</div>
		</div>	
	</div>
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<a href="https://valorserver.com/ranks" class="text-gray-300 text-left p-2"><img class="w-12 h-12 inline-block m-2" src="https://vwiki.valorserver.com/api/item/picture/Vial of Speed"> Once you are Ascended, you have room for +10 more points in each Stat. To max those Ascended Stats, you must consume vials. Vials drop from bosses, or can be crafted by dragging two greaters together. You may also crush vials for Gold & Onrane from the Vial Crusher in nexus.</a>
		<img class="align-center" src="https://media.discordapp.net/attachments/732139834876887100/754656562563252315/lspic.png?width=365&height=483">
	</div>		
	

	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-blue-700 hover:text-blue-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-blue-400 rounded-lg">Raids</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-blue-400 inline-flex"></div>
			</div>
		</div>	
	</div>
	
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-yellow-700 hover:text-yellow-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-yellow-400 rounded-lg">Marketplace & Currencies</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-yellow-400 inline-flex"></div>
			</div>
		</div>	
	</div>
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">
			<p class="text-gray-300 text-bold text-left">The Marketplace is where you can sell items for gold to other players. There is a 13% sales tax, and removals cost 50 gold. The marketplace is hundreds of rotating shop slots, arrangedw by categories.</p>
			<p class="text-gray-300 inline-block text-left p-2"><img class="inline-block w-12 h-12 m-2" src="https://vwiki.valorserver.com/api/item/picture/100000%20Gold"> Gold is used to purchase items from the Marketplace, it is the main currency of the server for trading with, it can also be used for buying Backpacks, Character Slots and Vault Chests.
Gold drops from mid-game content and beyond such as: Crypt of the Illusionist, Catacombs, and Raids.</p>
			<p class="text-gray-300 inline-block text-left p-2"><img class="w-12 h-12 inline-block m-2" src="https://vwiki.valorserver.com/api/item/picture/Onrane"> Onrane is used to open Elite Lootboxes, forge vials, ascend Sor Crystals,buy legendary items from the Zol Shop or the Titan Shop and activate new Nodes and Marks. Raids are the best source of Onrane because they drop Onrane Caches which can give 2-10 Onrane and the final boss of the raid can drop an Ultimate Onrane Cache that gives 12-20 Onrane. Elite Lootboxes can also drop Onrane Caches and Ultimate Onrane Caches.</p>
			<a href="https://valorserver.com/ranks" class="text-gray-300 text-left p-2"><img class="w-12 h-12 inline-block m-2" src="https://vwiki.valorserver.com/api/item/picture/Kantos%20Cache"> Kantos is the premium currency for Valor, meaning it can only be obtained through donating. You recieve 1200 kantos per $10 donated. These Kantos can only be used for opening Premium Lootboxes Skin Effect Chips, Cosmetic Pets, Housing Items, Global Loot Bosts & much more. To see these effects, you must have Particle Master enabled in the experimental options.</a>
			<p class="text-gray-300 text-left p-2"><img class="w-12 h-12 inline-block m-2" src="https://vwiki.valorserver.com/api/item/picture/1000%20Fame"> Fame is only used for making guilds, changing your name and purchasing some items from the nexus. Fame is also an accepted currency in the housing Home Depot.</p>
        </div>
	</div>		
		
		
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-purple-700 hover:text-purple-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-purple-400 rounded-lg">Lootboxes</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-purple-400 inline-flex"></div>
			</div>
		</div>	
	</div>
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">
			<img class="inline-block" src="https://cdn.discordapp.com/attachments/732139834876887100/754660806557433926/faq1.png">
			<p class="text-gray-300 inline-block text-left p-2">Lootboxes drop across the realm from different monsters or quests depending on the tier. They are unboxed via the lootbox menu and give you a random reward from a predetirmined set of options.</p>
		</div>
	</div>	
		
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="text-center mb-10">
            <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-red-400 hover:text-red-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-red-200 rounded-lg">Item Tiers</h1>
			<div class="flex mt-6 justify-center">
				<div class="w-16 h-1 rounded-full bg-red-200 inline-flex"></div>
			</div>
		</div>	
	</div>
	
	<div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
		<div class="mb-4 p-2 mt-3 pb-2 bg-gray-800  rounded-lg text-left">
			<p class="text-gray-300 text-left p-2"><img class="inline-block w-12 h-12 m-2" src="forge.png"> The Forge station is where you craft legendaries and open shards. The rename menu is also located here.</p>
			<p class="text-gray-300 text-left p-2"><span class="rounded-lg shadow-lg inline-block m-2 p-1 text-white bg-yellow-700">Ancient Relic</span> This the rarest and most powerful tier in the game. ARs are extremely coveted and deal insane damage.</p>
			<p class="text-gray-300 text-left p-2"><span class="rounded-lg shadow-lg inline-block m-2 p-1 text-white bg-yellow-400">Legendary</span> Legendaries are super rare and powerful items. They can have special effects, that impact and drastically change gameplay. These are gained as very rare drops, or from essence crafting and opening shards.</p>
			<p class="text-gray-300 text-left p-2"><span class="rounded-lg shadow-lg inline-block m-2 p-1 text-white bg-red-400">Trial Set</span> Trial items are a relatively new tier, and they are items from loot sets which only drop from Trials. They are on-par with LGs.</p>
			<p class="text-gray-300 text-left p-2"><span class="rounded-lg shadow-lg inline-block m-2 p-1 text-white bg-red-500">Fabled</span> These drop from raids and strong quest bosses. They are themed around their boss, and are direct upgrades to UTs. They may also be gained from the Fabled Loot crate.</p>

        </div>
	</div>		


  </div>


</template>

<script>
export default {
  name: "Mechanics",
};
</script>
<style scoped>

.about {
  cursor:url('https://vwiki.valorserver.com/api/item/picture/Insignia%20of%20Valor'), auto;
}

</style>