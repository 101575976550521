<template>
<div>
<div v-if="isError">
  <div class=" justify-center flex bg-gray-900 items-center h-screen">
    <h1 class="mb-2 p-2 mt-3 text-2xl text-gray-700 hover:text-gray-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-gray-900 rounded-lg">This wasn't found!</h1>
      <router-link to="/bosses" class="p-2 text-white bg-blue-600 m-2 rounded-lg font-blocky"> Back</router-link>
  </div>
</div>
<div v-else>
 <div class="container px-5 mx-auto">
            <div class="flex flex-col w-full mb-2 text-left ">
              <h1 class="mb-2 text-3xl text-gray-100 tracking-tighter lg:text-7xl md:text-4xl"> {{ $route.params.name }} </h1>
            </div>
            <div class="flex flex-col lg:flex-row lg:space-x-12">
              <div class="w-full max-w-screen-sm m-auto mt-12 lg:w-1/4">
                <div class="p-4 transition duration-500 ease-in-out transform bg-gray-800 rounded-lg ">                  
                    <h1 class="mb-4 p-2 mt-3 pb-2 text-2xl text-gray-700 hover:text-gray-300 shadow-lg lg:text-5xl md:text-3xl text-center font-blocky bg-gray-900 rounded-lg">Quest</h1>

                  <img :src="'https://vwiki.valorserver.com/api/boss/picture/' + $route.params.name" class="object-cover content-center text-center mx-auto w-32 h-32">

                  <div class="container grid grids-coll-3 gap-4 text-center pt-2 lg:pt-4">
                
                      <span v-if="boss.StasisImmune != undefined" class="p-2 text-xs font-semibold py-1 px-2 uppercase rounded text-purple-700 bg-purple-300 uppercase last:mr-0 mr-1">
                        Statis Immune
                      </span>

                      <span v-if="boss.DazedImmune != undefined" class="p-2 text-xs font-semibold py-1 px-2 uppercase rounded text-red-700 bg-yellow-300 uppercase last:mr-0 mr-1">
                        Dazed Immune
                      </span>

                      <span v-if="boss.ParalyzeImmune != undefined" class="p-2 text-xs font-semibold py-1 px-2 uppercase rounded text-red-700 bg-yellow-300 uppercase last:mr-0 mr-1">
                        Paralyze Immune
                      </span>

                      <span v-if="boss.WeakImmune != undefined" class="text-xs p-2 font-semibold py-1 px-2 uppercase rounded text-red-700 bg-yellow-300 uppercase last:mr-0 mr-1">
                        Weak Immune
                      </span>

                      <span v-if="boss.DazedImmune != undefined" class="text-xs p-2 font-semibold py-1 px-2 uppercase rounded text-red-700 bg-yellow-300 uppercase last:mr-0 mr-1">
                        Paralyze Immune
                      </span>


                      <span v-if="boss.StunImmune != undefined" class="text-xs p-2 font-semibold py-1 px-2 uppercase rounded text-red-700 bg-red-300 uppercase last:mr-0 mr-1">
                        Stun Immune
                      </span>
                  
                      <span v-if="boss.ArmorBrokeImmune != undefined" class="text-xs p-2 font-semibold py-1 px-2 uppercase rounded text-blue-700 bg-blue-300 uppercase last:mr-0 mr-1">
                        Armor Broken Immune
                      </span>
                      
                      <span v-if="boss.Flying != undefined" class="text-xs p-2 font-semibold py-1 px-2 uppercase rounded text-gray-400 bg-white uppercase last:mr-0 mr-1">
                        Flying / Hovering
                      </span>
                  </div>
                </div>
              </div>
              
              <div class="w-full px-4 mt-12 text-lg leading-snug tracking-tight text-blueGray-500 lg:px-0 lg:w-3/4">
                <div class="header p-4 pl-4 mb-6 italic font-bold tracking-tight text-yellow-500 bg-gray-800 rounded-lg lg:text-4xl"> Basic Stats </div>
                 <div class="pb-6 pl-4 py-2 mr-1 mb-6 bg-gray-800  rounded-lg text-center">
                     <p v-if="boss.MaxHitPoints != undefined" class="text-gray-300">Starting Health: {{ JSON.parse(boss.MaxHitPoints) }} (Before Scaling)</p>
                     <p v-if="boss.Defense != undefined" class="text-gray-300">Defense: {{ JSON.parse(boss.Defense) }}</p>
                    <p v-if="boss.Level != undefined" class="text-gray-300">Quest Level: {{ boss.Level }}</p>

                 </div>
             <div  v-if="boss.Projectile != undefined">
                  <div class="header p-4 pl-4 mb-6 font-bold tracking-tight text-purple-300 bg-gray-800 rounded-lg lg:text-4xl"> Projectiles </div>
                  <div class="pb-6 pl-4 py-2 mr-1 mb-6 bg-gray-800  rounded-lg text-left">
                  <p  v-for="proj in boss.Projectile" v-bind:key="proj" class="pb-2 text-gray-300"> {{ proj }}</p>
                </div>
              </div>

                <div class="header p-4 pl-4 mb-6 italic font-bold tracking-tight text-green-300 bg-gray-800 rounded-lg lg:text-4xl"> Drops </div>
                <div class="pb-6 pl-4 py-2 mr-1 mb-6 bg-gray-800  rounded-lg text-left">
                    <p class="text-3xl lg:text-5xl"> Coming Soon</p>
                </div>

              </div>
            </div>
          </div>
 </div>       
 </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Item",
  data() {
    return {
      boss: "",
      error: "",
      isError: false,
    };
  },

  methods: {    
    hexToDec(hexString){
        return parseInt(hexString, 16);
    },
    async getData() {
      try {
        var name = this.$route.params.name;
        const response = await axios.get("https://vwiki.valorserver.com/api/boss/" + name);
        this.boss = response.data;
        console.log(response);
      
      } catch (error) {
        console.log(error);
        this.isError = true;
        this.error = "Item not found!";
        this.$route.params.name = "";
        this.boss = "";

      }
    },
  },

  created() {
    this.getData();
  },
};
</script>
